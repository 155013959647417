.Skills-Search
{

       border: 1px solid #EF9F37;
}
.Card-Layout
{
     font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
     height: 14rem;
     padding: 0.3rem;

} 
.RPLN-Search
{
    border: 1px solid #3363A0;

}
.Card-Layout:hover
{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 1s;
    scale: 1.1;
}

*
{
      font-weight: 500;
}