* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: 'Shell Font', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.App {
  text-align: center;
}