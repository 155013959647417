.homepage-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10em;

}

.grow-badge
{
    scale: scale(0.7);
}
