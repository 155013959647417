.ReviewButton {
    background-color: #ef9f37;
    color: white;
    border: #ef9f37;
    margin-top: 1em;
    margin-left: auto;
    margin-right: 1em;
    border-radius: 0.5em;
    padding: 0.4em;
}
.span-group
{
    font-size: 1em;
    font-weight: 600;
    color: #000;
    margin: auto 0px auto 0px;
}
.note
{
     font-size: 0.8em;
}
.rg_names
{
    font-size: 0.8rem;
    font-weight: 600;
    height: 4rem;
    padding: 0.5rem;
    background: #66c5d2;
    color: #3363A0;
    border-radius: 15px;
    margin: auto 0px auto 0px;
    margin-top: 2rem;
}
.delete_btn
{
    background:none;
    color: white;
    border:none;
    margin-top: 1em;
    margin-left: auto;
    margin-right: 1em;
    padding: 0.4em;
}

.rg_container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin: auto;
    width: 85%;
    height: 7rem; /* Set the desired fixed height */
    border-radius: 5px;
    overflow-y: scroll;
}
  .inputsContainer {
    display: "grid";
    align-items: "center";
    padding: "1rem";
    gap: "0.5em";
  }

.rg_names{
    font-size: 0.8rem;
    background-color: rgba(0, 60, 136, 0.1);
    /* padding:auto; */
    list-style: none;
    margin-left: 2em;
    padding-bottom: 0.2em;
}
.rg_list
{
    list-style: none;
}
.displayRgsContainer
{
    margin-top: 20px;
    margin-left: 10%;
    width: 85%;
    border-radius: "5px";
    overflow: "scroll";
  }